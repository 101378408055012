import { render, staticRenderFns } from "./TheSpaceInstances.vue?vue&type=template&id=50831c9e&scoped=true&"
import script from "./TheSpaceInstances.vue?vue&type=script&lang=js&"
export * from "./TheSpaceInstances.vue?vue&type=script&lang=js&"
import style0 from "./TheSpaceInstances.vue?vue&type=style&index=0&id=50831c9e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50831c9e",
  null
  
)

export default component.exports